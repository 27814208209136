// Color system
$blue: #5e50f9;
$indigo: #6610f2;
$purple: #6a008a;
$pink: #e91e63;
$red: #f96868;
$orange: #f2a654;
$yellow: #f6e84e;
$green: #46c35f;
$teal: #58d8a3;
$cyan: #57c7d4;
$black: #000;
$white: #ffffff;
$white-smoke: #f2f7f8;
$violet: #41478a;
$darkslategray: #2e383e;
$dodger-blue: #3498db;

$colors: (
    blue: $blue,
    indigo: $indigo,
    purple: $purple,
    pink: $pink,
    red: $red,
    orange: $orange,
    yellow: $yellow,
    green: $green,
    teal: $teal,
    cyan: $cyan,
    white: $white,
    gray: #434a54,
    gray-light: #aab2bd,
    gray-lighter: #e8eff4,
    gray-lightest: #e6e9ed,
    gray-dark: #0f1531,
    black: #000000,
);

$theme-colors: (
    primary: var(--max-primary),
    secondary: #3a3636,
    success: #10b759,
    info: #66d1d1,
    warning: #fbbc06,
    danger: #ff8400,
    light: #ececec,
    dark: #282f3a,
    primary-muted: #b1cfec,
    info-muted: #7ee5e5,
    danger-muted: #f77eb9,
);

$theme-gradient-colors: (
    primary: linear-gradient(230deg, #759bff, #843cf6),
    secondary: linear-gradient(to right, #e7ebf0, #868e96),
    success: linear-gradient(45deg, #7bffce, #30c93e),
    info: linear-gradient(to bottom, #0e4cfd, #6a8eff),
    warning: linear-gradient(135deg, #ffc480, #ff763b),
    danger: linear-gradient(316deg, #fc5286, #fbaaa2),
    light: linear-gradient(to right, #cfd9df 0%, #e2ebf0 100%),
    dark: linear-gradient(to right, #7d7979 0%, #000000 100%),
);
// Color system

// Color variables
$content-bg: #f9fafb;
$border-color: #e8ebf1;
// Color variables

// Social colors
$social-colors: (
    twitter: #2caae1,
    facebook: #3b579d,
    google: #dc4a38,
    linkedin: #0177b5,
    pinterest: #cc2127,
    youtube: #e52d27,
    github: #333333,
    behance: #1769ff,
    dribbble: #ea4c89,
    reddit: #ff4500,
    instagram: #fb3958,
);
// Social colors

// Font
$type1: "Overpass", sans-serif;

$default-font-size: 0.875rem; // 14px as base font size
$default-font-weight: 400;
$default-line-height: 1.5;
$lead-font-size: 1.19rem;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
    (
        10: 10%,
        20: 20%,
        25: 25%,
        30: 30%,
        40: 40%,
        50: 50%,
        60: 60%,
        75: 75%,
        80: 80%,
        90: 90%,
        100: 100%,
        auto: auto,
    ),
    $sizes
);

$text-muted: #686868;
// $body-color: #1b2e4b;
$body-color: #000;
// Font

// Footer
$footer-height: 75px;
$footer-bg: $content-bg;
$footer-color: color(dark);
// Footer

// Badges
// $badge-font-size:                   75%;
$badge-font-weight: 700;
$badge-padding-y: 4px;
$badge-padding-x: 5px;
$badge-border-radius: 3px;
// Badges

// Buttons
$button-fixed-width: 150px;
$btn-padding-y: 0.5rem;
$btn-padding-x: 1rem;
$btn-line-height: 1.5;

$btn-padding-y-xs: 0.35rem;
$btn-padding-x-xs: 0.75rem;

$btn-padding-y-sm: 0.4rem;
$btn-padding-x-sm: 0.85rem;
$btn-line-height-sm: 0.85rem;

$btn-padding-y-lg: 0.8rem;
$btn-padding-x-lg: 1.5rem;
$btn-line-height-lg: 1;

$btn-font-size: 0.875rem;
$btn-font-size-xs: 0.625rem;
$btn-font-size-sm: 0.75rem;
$btn-font-size-lg: 1.3rem;

$btn-font-weight: 600;

$btn-border-radius: 0.1875rem;
$btn-border-radius-xs: 0.1875rem;
$btn-border-radius-sm: 0.1875rem;
$btn-border-radius-lg: 0.1875rem;
// Buttons

// Tooltip
//default styles
$tooltip-font-size: 0.75rem;
$tooltip-padding-y: 0.4rem;
$tooltip-padding-x: 0.75rem;
$tooltip-border-radius: 0.375rem;
// Tooltip

// Forms
$input-bg: color(white);
$input-border-radius: 2px;
$input-placeholder-color: #c9c8c8;
$input-font-size: 0.875rem;

$input-padding-y: 0.5rem;
$input-padding-x: 1rem;

$input-line-height: 1;
$input-line-height-sm: 2;

$input-padding-y-xs: 0.3rem;
$input-padding-x-xs: 0.75rem;

$input-padding-y-sm: 0.4rem;
$input-padding-x-sm: 0.875rem;

$input-padding-y-lg: 0.94rem;
$input-padding-x-lg: 1rem;

$input-font-size-sm: 0.8rem;

$input-group-addon-bg: rgba(theme-color(primary), 0.08);
// Forms

// Dropdowns
$dropdown-border-color: #f8f8f8;
$dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
$dropdown-divider-bg: $border-color;
$dropdown-link-color: $body-color;
$dropdown-header-color: $body-color;
$dropdown-link-hover-bg: #eaeaf1;
$dropdown-font-size: 13px;
// Dropdowns

// Tables
$table-accent-bg: #eee;
$table-hover-bg: #f9fafb;
$table-cell-padding: 0.875rem 0.9375rem;
$table-border-color: $border-color;

$table-inverse-bg: #2a2b32;
$table-inverse-color: color(white);
// Tables

// Measurment and property variables
$border-property: 1px solid $border-color;
$card-spacing-y: 1.5rem;
$card-padding-y: 1.5rem;
$card-padding-x: 1.5rem;
$card-spacer-y: 0.875rem;
$card-spacer-x: 1.5rem;
$card-border-radius: 0.25rem;
$card-cap-bg: rgba(0, 0, 0, 0);
$card-inner-border-radius: 0;
$card-border-color: #f2f4f9;
$card-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
$card-title-color: $body-color;
$card-description-color: #76838f;
$grid-gutter-width: 1.5rem;
$action-transition-duration: 0.2s;
$action-transition-timing-function: ease;
// Measurment and property variables

// Breadcrumb
// default styles
$breadcrumb-padding-y: 0.56rem;
$breadcrumb-padding-x: 1.13rem;
$breadcrumb-item-padding: 0.5rem;
$breadcrumb-margin-bottom: 1rem;
$breadcrumb-font-size: $default-font-size;
// $breadcrumb-bg:                     transparent;
$breadcrumb-border-color: $border-color;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-700;
$breadcrumb-divider: "/";
// custom styles
$breadcrumb-custom-padding-y: 0;
$breadcrumb-custom-padding-x: 0;
$breadcrumb-custom-item-padding-y: 0.56rem;
$breadcrumb-custom-item-padding-x: 10px;
$breadcrumb-custom-item-color: $black;
$breadcrumb-item-bg: #dbe3e6;
// Breadcrumb

// Modals
$modal-inner-padding: 0.937rem;
$modal-dialog-margin: 10px;
$modal-dialog-margin-y-sm-up: 30px;
$modal-title-line-height: $line-height-base;
$modal-content-bg: $white;
$modal-content-box-shadow-xs: 0 3px 9px rgba($black, 0.5);
$modal-content-box-shadow-sm-up: 0 5px 15px rgba($black, 0.5);

$modal-backdrop-bg: $black;
$modal-backdrop-opacity: 0.5;

$modal-header-border-color: $border-color;
$modal-content-border-color: $border-color;
$modal-footer-border-color: $border-color;

$modal-header-border-width: $border-width;
$modal-content-border-width: $border-width;
$modal-footer-border-width: $border-width;

$modal-header-padding-x: 26px;
$modal-header-padding-y: 25px;

$modal-body-padding-x: 26px;
$modal-body-padding-y: 35px;

$modal-footer-padding-x: 31px;
$modal-footer-padding-y: 15px;

$modal-lg: 800px;
$modal-md: 500px;
$modal-sm: 300px;
$modal-fade-transform: scale(0.8);
$modal-transition: transform 0.4s ease;
// Modals

// Pagination
$pagination-color: theme-color(primary);
$pagination-active-bg: theme-color(primary);
$pagination-active-border-color: theme-color(primary);
// Pagination

// Progressbar
$progress-bar-bg: theme-color(primary);
// Progressbar

// Tabs
$nav-tabs-border-color: #ebedf2;
$nav-tabs-link-bg: #f6f8fa;
$nav-tabs-link-color: #000000;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color $nav-tabs-border-color
    $nav-tabs-border-color;
$nav-tabs-link-active-color: $body-color;
$nav-tabs-link-active-bg: $white;
$nav-tabs-link-active-border-color: $nav-tabs-border-color
    $nav-tabs-border-color $nav-tabs-link-active-bg;
$nav-pills-custom-bg: #fcfcfd;
// Tabs

// Content-nav
$content-nav-width: 220px;

// Sidebar
$sidebar-light-bg: $white;
$sidebar-light-border-color: #f2f4f9;

$sidebar-dark-bg: #0c1427;
$sidebar-dark-border-color: #344258;
// Sidebar
